import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/etapa2.css";
import { Homework, BoxProps, Correction } from "../../components/SharedTypes";
import MyNavbar from "../../components/NavBar";
import CorrectionBox from "../../components/CorrectionBox";
import BlockedCorrectionBox from "../../components/BlockedCorrectionBox";
import { useAuthHeader, useAuthUser } from "react-auth-kit";

const Etapa2: React.FC = () => {
  const [pendingHomework, setPendingHomework] = useState<Homework[]>([]);
  const [pastHomework, setPastHomework] = useState<Homework[]>([]);
  const authUser = useAuthUser();
  const userId = authUser()?.id;
  const backendUrl = process.env.REACT_APP_API_URL;
  const correctionsEndpoint = "/corrections";
  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}${correctionsEndpoint}?corrector=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = response.data.data;

      const combinedAssignmentData: Homework[] = [];

      for (let assignment of responseData) {
        const title = assignment.assigmentUser.assignment.number;
        const startDate = assignment.assignmentSchedule.startDate;
        const endDate = assignment.assignmentSchedule.endDate;
        const status = assignment.assignmentSchedule.status;
        const stat = assignment.assigmentUser.status;
        const sectionNumber = assignment?.assigmentUser?.assignment?.courseSection?.number;
        const existingHomework = combinedAssignmentData.find(
          (homework) => homework.title === title
        );

        if (existingHomework?.corrections !== undefined) {
          existingHomework.corrections.push({
            id: assignment.id,
            score: assignment.score,
            status: stat,
            containsAuthorData: assignment.containsAuthorData,
            justification: assignment.justification,
            attachment: assignment.attachment,
            corrected: assignment.corrected,
            sectionNumber: sectionNumber?.toString() || ""
          });
        } else {
          const corrections: Correction[] = [
            {
              id: assignment.id,
              score: assignment.score,
              status: stat,
              containsAuthorData: assignment.containsAuthorData,
              justification: assignment.justification,
              attachment: assignment.attachment,
              corrected: assignment.corrected,
              sectionNumber: sectionNumber?.toString() || ""
            },
          ];

          const newHomework: Homework = {
            title: title,
            deadline: new Date(endDate),
            startDate: new Date(startDate),
            status: status,
            corrections,
          };

          combinedAssignmentData.push(newHomework);
        }
      }

      const currentDate = new Date().getTime();
      const pending: Homework[] = [];
      const past: Homework[] = [];

      combinedAssignmentData.forEach((homework) => {
        const deadlineDate = new Date(homework.deadline).getTime();
        if (currentDate < deadlineDate) {
          pending.push(homework);
        } else {
          past.push(homework);
        }
      });

      setPendingHomework(pending);
      setPastHomework(past);
    } catch (error) {
      console.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
    //console.log(pastHomework)
  }, []);

  const pendingBoxProps: BoxProps = {
    type: "pending",
    homeworkList: pendingHomework,
    //homeworkList: pastHomework,
  };

  const pastBoxProps: BoxProps = {
    type: "past",
    homeworkList: pastHomework,
  };

  return (
    <div className="App1">
      <MyNavbar />
      <div className="title-text">Etapa 2: Corregir Ensayo</div>
      <div className="alerta2">
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <strong>Recuerda:</strong> Debes corregir los dos ensayos que se te
          fueron asignados. <strong>NO</strong> debes incluir el puntaje que les asignaras. Si el ensayo no cumple con el criterio establecido,
          marcarlo con un -1.
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="box">
        <CorrectionBox {...pendingBoxProps} />
        <BlockedCorrectionBox {...pastBoxProps} />
      </div>
    </div>
  );
};

export default Etapa2;
