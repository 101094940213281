import React from 'react';

type RubricModalProps = {
  showRubricModal: boolean;
  setShowRubricModal: React.Dispatch<React.SetStateAction<boolean>>;
  sectionNumber: string;
};

const RubricE2Modal: React.FC<RubricModalProps> = ({ showRubricModal, setShowRubricModal, sectionNumber }) => {

  const selectSectionLink = (sectionNumber: string) => {
    if (sectionNumber === "1") {
      return "https://cursos.canvas.uc.cl/courses/83142/files/folder/Ensayos";
    } else if (sectionNumber === "2") {
      return "https://cursos.canvas.uc.cl/courses/83525/files/folder/Ensayos";
    } else if (sectionNumber === "2") {
      return "https://cursos.canvas.uc.cl/courses/83677/files/folder/Ensayos";
    } else {
      return "";
    }
  }
  
  return (
    <div>
      {showRubricModal && (
        <div className="modal fade show d-block bg-slate-200" tabIndex={-1}>
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Rúbrica de Corrección</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowRubricModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <h5>Recordatorio</h5>
                <div className='paragraph'>
                  <p>Acuerdate de ingresar a canvas para revisar la pauta de este ensayo.</p>
                  
                <p className='mt-2'>Puedes revisar la pauta en canvas: <a href={selectSectionLink(sectionNumber)} target="_blank" rel="noopener noreferrer">{selectSectionLink(sectionNumber)}</a></p>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RubricE2Modal;