import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useAuthHeader } from "react-auth-kit";
import "../../styles/customForms.css";
import AdminNavbar from '../../components/AdminNavbar';
import { Button, Col, Row } from 'react-bootstrap';
import { Divider } from 'antd';

export default function FormAdmin() {
  const [ensayoNumber, setEnsayoNumber] = useState<any>();
  const authHeader = useAuthHeader();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const backendUrl = process.env.REACT_APP_API_URL;
  const [essays, setEssays] = useState<any>([]);
  const [amountOfReviews, setAmountOfReviews] = useState<any>();
  const [collaborators, setCollaborators] = useState<any>();


  const handleFourthStageDivision = async (values: any) => {
    setIsDisabled(true);
    setLoading(true);

    const manualRecorrections: any = Object.keys(values)
    .filter((key) => key.startsWith('manualCorrections-'))
    .map((key) => {
      const userId = parseInt(key.split('-')[1], 10); // Extract userId from the key
      const correctionNumber = values[key];
      return [userId, correctionNumber];
    });

    let postData: { equally: boolean; number: any; collaborators?: any } = {
      equally: values.equally === 'automatic' ? true : false,
      number: ensayoNumber,
    };

    try {

      if (postData.equally === false) {
        postData.collaborators = manualRecorrections;
        if (manualRecorrections.reduce((total: number, arr: any[]) => total + (typeof arr[1] === 'number' ? arr[1] : 0), 0).toString() != amountOfReviews.recorrections.toString()) {
          toast.error(`Cantidad de recorrecciones con suma por ayudantes no son iguales`, {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
          });
          throw new Error("Cantidad de recorrecciones con suma por ayudantes no son iguales");
        }
      }

      await axios.post(`${backendUrl}/stages/fourth/divide`, postData, {
        headers: {
          Authorization: authHeader(),
        },
      });
      toast.success('Asignación de recorecciones exitosa', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      setIsDisabled(false);
      await sleep(2000);
      window.location.reload();

      // Additional logic after successful POST request
    } catch (error) {
      const error_log = error as any;
      toast.error(`Error en la Asignación de recorecciones: ${error_log?.response?.data?.message}`, {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
      });
      setLoading(false);
      setIsDisabled(false);
      await sleep(2000);
      window.location.reload();
      // Handle error
    }
  };

  function sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const validationSchema = Yup.object().shape({
    ensayo: Yup.number().required('Required'),
  });

  const getManualCorrectionsKey = (id: string) => `manualCorrections-${id}`;

  const validationSchemaDivider = Yup.object().shape({
    equally: Yup.string().required('Este campo es requerido'),
    collaborators: Yup.array().of(
      Yup.object().shape({
        userId: Yup.string().required('User ID is required'),
        corrections: Yup.number().required('Number of corrections is required').positive('Must be a positive number'),
      })
    ),
  });

  const getEssays = async () => {
    setIsDisabled(true);
    try {
      const response = await axios.get(`${backendUrl}/assignments/numbers`, {
        headers: {
          Authorization: authHeader(),
        },
      });
      setIsDisabled(false);
      setEssays(response.data.data);
    } catch (error) {
      setIsDisabled(false);
    }
  }

  const getAmountOfReviews = async (ensayoNum: number) => {
    setEnsayoNumber(ensayoNum);
    setIsDisabled(true);
    try {
      const response = await axios.get(`${backendUrl}/stages/fourth/recorrections?number=${ensayoNum}`, {
        headers: {
          Authorization: authHeader(),
        },
      });
      setIsDisabled(false);
      await getCollaborators();
      setAmountOfReviews(response.data.data);
    } catch (error) {
      setIsDisabled(false);
    }
  }

  const getCollaborators = async () => {
    setIsDisabled(true);
    try {
      const response = await axios.get(`${backendUrl}/users?role=COLLABORATOR`, {
        headers: {
          Authorization: authHeader(),
        },
      });
      setIsDisabled(false);
      const rsp = response.data.data;
      setCollaborators(rsp);
    } catch (error) {
      setIsDisabled(false);
    }
  }

  useEffect(() => {
    getEssays()
  }, [])

  return (
    <div className="Admin">
      <AdminNavbar />
      <div className="container mt-3 col-lg-10">
        <div className="d-flex justify-content-center rounded pb-2 custom-bg-gray">
          <div className="col-lg-6 col-11">
            <div className="title-text py-4 mb-0">Asignaciones de recorrecciones</div>

            <Divider className='mt-0'>Seleccionar Ensayo</Divider>

            <Formik
              initialValues={{ ensayo: '' }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const ensayoNum = parseInt(values.ensayo, 10); // Convert the string to a number
                if (!isNaN(ensayoNum)) {
                  getAmountOfReviews(ensayoNum);
                } else {
                  console.error('Numero de ensayo inválido');
                }
              }}
            >
              {({ touched, errors }) => (
                <Form>
                  <Row className="justify-content-center">
                    <Col xs={10} md={6} className="py-1">
                      <div className="form-group">
                        <Field
                          as="select"
                          name="ensayo"
                          className={`form-control ${
                            touched.ensayo && errors.ensayo ? 'is-invalid' : ''
                          }`}
                        >
                          <option value="">Selecciona un ensayo</option>
                          {essays
                            ? essays.map((essay: any, index: any) => (
                              <option key={index} value={essay.number}>
                                Ensayo {essay.number}
                              </option>
                            ))
                            : null}
                        </Field>
                        {touched.ensayo && errors.ensayo && (
                          <div className="invalid-feedback">{errors.ensayo}</div>
                        )}
                      </div>
                    </Col>
                    <Col xs={10} md={6} className="py-1">
                      <Button
                        type="submit"
                        disabled={isDisabled}
                        variant="primary"
                      >
                        Encontrar Correcciones
                        {loading ? (
                          <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        ) : (
                          <></>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>

            <Divider>Datos de Recorrecciones</Divider>

            <Row className="justify-content-center">
              <Col xs={10} md={6}>
                <p className="bg-secondary text-light rounded p-2">
                  Recorrecciones: {amountOfReviews != null ? amountOfReviews.recorrections : ''}
                </p>
              </Col>

              <Col xs={10} md={6}>
                <p className="bg-secondary text-light rounded p-2">
                  Ayudantes: {collaborators ? collaborators.length : ''}
                </p>
              </Col>
            </Row>

            <Divider>Dividir Recorrecciones</Divider>

            <Formik
              initialValues={{ equally: 'automatic', manualCorrections: ''   }}
              validationSchema={validationSchemaDivider}
              onSubmit={(values) => {
                handleFourthStageDivision(values);
              }}
            >
              {({ touched, errors, values }) => (
                <Form>
                  <Row className="justify-content-center mb-2">
                    <Col xs={10} md={6} className="py-1">
                      <div className="form-group">
                        <Field
                          as="select"
                          name="equally"
                          className={`form-control ${
                            touched.equally && errors.equally ? 'is-invalid' : ''
                          }`}
                        >
                          <option value="">Selecciona forma de division</option>
                            <option key={1} value={'automatic'}>
                              Automatico
                            </option>
                            <option key={2} value={'manual'}>
                              Manual
                            </option>
                        </Field>
                        {touched.equally && errors.equally && (
                          <div className="invalid-feedback">{errors.equally}</div>
                        )}
                      </div>
                    </Col>

                    <Col xs={10} md={6} className="py-1 w-full">
                      <Button
                        type="submit"
                        disabled={isDisabled || amountOfReviews === null ||  !collaborators }
                        variant="primary"
                        className='px-4'
                      >
                        Dividir Correcciones
                        {loading ? (
                          <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        ) : (
                          <></>
                        )}
                      </Button>
                    </Col>
                  </Row>

                  

                  {values.equally === 'manual' && (
                     <>
                      <Row>
                        <span className="text-muted">
                          La suma no puede superar el máximo o no se enviará la solicitud
                        </span>
                      </Row>
                      {collaborators?.map((collaborator: any) => (
                        <Row key={collaborator.id} className="mb-3">
                          <Col xs={6} md={6} className="d-flex align-items-center">
                            <label htmlFor={getManualCorrectionsKey(collaborator.id)} className="me-2">
                              {collaborator.name+' '+collaborator.lastName}:
                            </label>
                          </Col>
                          <Col xs={6} md={6}>
                            <div className="form-group">
                              <Field
                                type="number"
                                step={1}
                                placeholder='0'
                                id={getManualCorrectionsKey(collaborator.id)}
                                name={getManualCorrectionsKey(collaborator.id) as keyof typeof touched}
                                className={`form-control ${
                                  touched[getManualCorrectionsKey(collaborator.id) as keyof typeof touched] &&
                                  errors[getManualCorrectionsKey(collaborator.id) as keyof typeof errors]
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                              {touched[getManualCorrectionsKey(collaborator.id) as keyof typeof touched] &&
                                errors[getManualCorrectionsKey(collaborator.id) as keyof typeof errors] && (
                                  <div className="invalid-feedback">
                                    {errors[getManualCorrectionsKey(collaborator.id) as keyof typeof errors]}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                      ))}
                     </>
                    )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {/* Any modals, toasts, or additional components can be added here */}
    </div>
  );
}
function sleep(arg0: number) {
  throw new Error('Function not implemented.');
}

